<template>
  <v-sheet class="wsRoundedHalf pa-6 mt-6">
    Archived orders
  </v-sheet>
</template>

<script>
export default {
  name: "activeOrders"
}
</script>

<style scoped>

</style>